export const getMenuData = [

  // {
  //  category: true,
  //  title: '',
  // },
  // {
  //   title: 'Database',
  //   key: 'database',
  //   url: '/database',
  //   icon: 'database',
  // },
  // {
  //   title: 'Main',
  //   key: 'main',
  //   url: '/main',
  //   icon: 'thunderbolt',
  // },
  {
    title: 'Main',
    key: 'viewscreens',
    url: '/main',
    icon: 'home',
  },
  {
    title: 'Segments',
    key: 'segments',
    url: '/segments',
    icon: 'team',
  },
  {
    title: 'Top matches',
    key: 'top',
    url: '/top-matches',
    icon: 'fire',
  },
  // {
  //   title: 'Banners',
  //   key: 'banners',
  //   url: '/banners',
  //   icon: 'picture',
  // },
  {
    title: 'Banners & Stories',
    key: 'promoImages',
    url: '/promo-images',
    icon: 'picture',
  },
  {
    title: 'Splashcreens',
    key: 'splashscreens',
    url: '/splashscreens',
    icon: 'mobile',
  },
  {
    title: 'Pages',
    key: 'events',
    url: '/events',
    icon: 'file',
  },
  {
    title: 'Affiliates',
    key: 'affiliates',
    url: '/affiliates',
    icon: 'cluster',
  },
  {
    title: 'Push-notifications',
    key: 'notifications',
    url: '/push-notifications',
    icon: 'bell',
  },
  // {
  //   title: 'Users',
  //   key: 'accounts',
  //   url: '/users',
  //   icon: 'team',
  // },
  //
  {
    title: 'Profiles',
    key: 'profiles',
    url: '/profiles/users',
    icon: 'user',
    // roles: ['admin'],
    children: [
      {
        title: 'Users',
        key: 'accounts',
        url: '/profiles/users',
        icon: 'link',
        // roles: ['admin'],
      },
      {
        title: 'Whitelist',
        key: 'whitelist',
        url: '/profiles/whitelist',
        icon: 'appstore',
        // roles: ['admin'],
      },
    ],
  },
  //
  {
    title: 'Analytics',
    key: 'analytics',
    url: '/analytics',
    icon: 'bar-chart',
  },
  {
    title: 'Updates',
    key: 'updates',
    url: '/updates',
    icon: 'rocket',
  },
  // {
  //   title: 'Accounts',
  //   key: 'users',
  //   url: '/accounts',
  //   icon: 'usb',
  // },
  {
    title: 'Subscriptions',
    key: 'subscriptions',
    url: '/subscriptions/matches',
    icon: 'schedule',
  },
  {
    title: 'Reviews',
    key: 'reviews',
    url: '/reviews',
    icon: 'message',
  },
  {
    title: 'Promocode',
    key: 'promocode',
    url: '/promocode',
    icon: 'gift',
  },
  {
    title: 'Icons & Colors',
    key: 'sports',
    url: '/sports',
    icon: 'bg-colors',
  },
  {
    title: 'Gifs',
    key: 'gifs',
    url: '/gifs',
    icon: 'video-camera',
  },
  {
    title: 'Betatests',
    key: 'betatest',
    url: '/betatests',
    icon: 'bug',
  },
  {
    title: 'Emergency mode',
    key: 'emergency',
    url: '/emergency-mode',
    icon: 'notification',
  },
  // {
  //   title: 'Betatests',
  //   key: 'betatest',
  //   url: '/betatests',
  //   icon: 'bug',
  //   roles: ['admin'],
  //   children: [
  //     {
  //       title: 'Betatests',
  //       key: 'betatest',
  //       url: '/betatests/beta',
  //       icon: 'bug',
  //       roles: ['admin'],
  //     },
  //     {
  //       title: 'Users',
  //       key: 'betausers',
  //       url: '/betatests/users',
  //       icon: 'user',
  //       roles: ['admin'],
  //     },
  //   ],
  // },
  {
    title: 'Refferal promocodes',
    key: 'refferal_promocodes',
    url: '/refferal_promocodes',
    icon: 'file',
  },
  // {
  //   title: 'Encryption',
  //   key: 'encryption',
  //   url: '/encryption',
  //   icon: 'barcode',
  // },
  // {
  //   title: 'Roles & permissions',
  //   key: 'permissions',
  //   url: '/permissions',
  //   icon: 'cluster',
  //   roles: ['admin'],
  // },
  {
    title: 'Settings',
    key: 'settings',
    url: '/settings',
    icon: 'setting',
    roles: ['admin'],
    children: [
      {
        title: 'Apps',
        key: 'apps',
        url: '/settings/apps',
        icon: 'appstore',
        roles: ['admin'],
      },
      {
        title: 'Deeplinks',
        key: 'deeplinks',
        url: '/settings/deeplinks',
        icon: 'link',
        roles: ['admin'],
      },
      {
        title: 'Firebase keys',
        key: 'firebase-keys',
        url: '/settings/firebase-keys',
        icon: 'api',
        roles: ['admin'],
      },
      {
        title: 'Languages',
        key: 'languages',
        url: '/settings/languages',
        icon: 'global',
        roles: ['admin'],
      },
    ],
  },
]
